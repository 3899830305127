@tailwind base;
@tailwind components;
@tailwind utilities;

.clearfix::after {
    content: " ";
    display: block;
    visibility: hidden;
    height: 0;
    font-size: 0.1em;
    line-height: 0;
    clear: both;
}
